import useHttp from "@fast-ninjas/hooks.use-http";
import { useCallback } from "react";

export default function usePayments(apiEndpoint?: string) {
  const { post, loading } = useHttp();

  const createPayment = useCallback(
    async (data: any) => {
      const response = await post(
        `${apiEndpoint}/api/online-listing/order`,
        data
      );
      if (!response.ok) throw response.statusText;
      return await response.json();
    },
    [post, apiEndpoint]
  );

  const confirmOTP = useCallback(
    async (
      otp: string,
      paymentId: string,
      externalPaymentId: string,
      apiVersion?: string
    ) => {
      const response = await post(
        `${apiEndpoint}/api/payments/${paymentId}/confirm-otp`,
        { otp, externalPaymentId, apiVersion }
      );
      if (!response.ok) throw response.statusText;
      return true;
    },
    [post, apiEndpoint]
  );

  return {
    processing: loading,
    createPayment,
    confirmOTP,
  };
}
