import { useCallback, useState } from "react";
import { Account } from "../types";

export default function useDelivery(account?: Account) {
  const [deliveryFee, setDeliveryFee] = useState(account?.deliveryFee ?? 0.0);

  const mappedLocations = account?.locations?.reduce((state: any, loc) => {
    state[loc.name] = loc;
    return state;
  }, {});

  const updateDelivery = useCallback(
    (method: string, locationName: string) => {
      let charge = 0.0;

      if (method === "deliver") {
        charge = account?.deliveryFee ?? 0.0;

        if (locationName) {
          const location = mappedLocations[locationName];
          if (location && location.charge) {
            charge = Number(location.charge);
          }
        }
      }

      setDeliveryFee(charge);
      return charge;
    },
    [setDeliveryFee, account, mappedLocations]
  );

  return { updateDelivery, deliveryFee };
}
