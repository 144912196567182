import useHttp from "@fast-ninjas/hooks.use-http";
import { Account } from "../types";
import { useCallback, useEffect, useState } from "react";
import config from "../configLoader";

export default function useProfile() {
  const { get, loading } = useHttp();
  const [account, setAccount] = useState<Account>();

  const loadProfile = useCallback(async () => {
    // We render the account from the server if it is available
    // This is to prevent the client from making an extra request
    // to the server
    const win = window as any;
    if (typeof win.__INITIAL_DATA === "object") {
      setAccount(win.__INITIAL_DATA);
      return;
    }

    // If the account is not available, we make a request to the server
    const cacheExpiration = 60 * 5; // 5 minutes
    const response: Account = await get(
      `${config.apiEndpoint}/api/online-listing`,
      {},
      cacheExpiration
    );

    if (response) {
      setAccount(response);
    }
  }, [get, setAccount]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  return {
    account,
    loading,
  };
}
