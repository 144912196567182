import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import useCart, { CartItem } from "../hooks/useCart";
import Currency from "../components/currency";
import Spacing from "../components/space";
import { QuantityEditor } from "./QuantityEditor";
import { useCallback, useMemo, useState } from "react";
import { Account } from "../types";

type CustomizeOrderProps = {
  account?: Account;
  item: CartItem;
  isOpen?: boolean;
  onClose?: () => void;
  onChange?: (item: CartItem) => void;
};

export default function CustomizeOrder(props: CustomizeOrderProps) {
  const { item, isOpen, account, onClose, onChange } = props;
  const [orderItem, setOrderItem] = useState(item);
  const { calculateCost } = useCart(account);

  const optionUpdated = useCallback(
    (quantity: number, idx: number) => {
      const itemCopy = { ...orderItem };
      if (!itemCopy.product.selection) return;
      itemCopy.product.selection[idx].quantity = quantity;
      setOrderItem(itemCopy);
    },
    [orderItem, setOrderItem]
  );

  const onChangedHandler = useCallback(() => {
    onChange && onChange(orderItem);
  }, [orderItem, onChange]);

  const itemCost = useMemo(
    () => calculateCost(orderItem),
    [orderItem, calculateCost]
  );

  return (
    <Dialog onClose={onClose} open={isOpen ?? false} fullWidth>
      <DialogTitle>
        <Typography>Order: {item.product.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center" style={{ width: "100%" }}>
          <img
            src={item.product.imageFile}
            style={{ maxWidth: 120, maxHeight: 120 }}
            alt={item.product.name}
          />
          <Spacing />

          <Box>
            {item.product.selection?.map((opt, idx) => (
              <Stack
                direction="row"
                key={`${opt.name}-${idx}`}
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontWeight={400} fontSize={11}>
                  {opt.name}
                </Typography>
                <Currency amount={opt.price} />
                <QuantityEditor
                  value={opt.quantity}
                  minimum={0}
                  maximum={25}
                  onChange={(v) => optionUpdated(v, idx)}
                />
              </Stack>
            ))}
          </Box>
          <Spacing />
          <Currency amount={Number(itemCost)} variant="large" />
          <Spacing />

          <Stack direction="row" justifyContent="flex-end" width="80%">
            <Button variant="contained" onClick={onChangedHandler} size="small">
              OK
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
