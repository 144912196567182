import { Button, ButtonProps, CircularProgress, styled } from "@mui/material";

type ProgressButtonProps = {
  isBusy?: boolean;
  busyMessage?: string;
} & ButtonProps;

const Progress = styled(CircularProgress)`
  width: 14px !important;
  height: 14px !important;
  margin: 2px 4px;
`;

const StyledButton = styled(Button)`
  padding: 12px;
  height: 60px;
`;

export default function ProgressButton(props: ProgressButtonProps) {
  const { isBusy, busyMessage, size, children, ...baseProps } = props;
  const busyDisplay = busyMessage ?? "Please wait...";
  const title = isBusy ? busyDisplay : "";

  const content = isBusy ? (
    <>
      <span>{busyDisplay}</span>
      <Progress />
    </>
  ) : (
    children
  );

  return (
    <StyledButton
      {...baseProps}
      size={size ?? "medium"}
      disabled={isBusy || baseProps?.disabled}
      title={title}
    >
      {content}
    </StyledButton>
  );
}
