import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#000",
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        color: "#dedede",
        style: {
          // backgroundColor: "#dedede",
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        style: {
          fontSize: "10px",
        },
      },
    },
  },
});

export default theme;
