import { Stack } from "@mui/system";
import { Layout } from "../layout";
import { ProductsDisplay } from "../components/ProductDisplay";
import useProfile from "../hooks/useProfile";
import { useCallback, useMemo, useState } from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Search, ShoppingBasketOutlined } from "@mui/icons-material";
import useCart, { CartItem } from "../hooks/useCart";
import CustomizeOrder from "../components/customize";
import { Product } from "../types";
import { ShoppingCart } from "../components/shoppingCart";
import { CenterContent } from "../components/centerContent";

export default function HomePage() {
  const { account, loading } = useProfile();
  const [isCartOpen, openCart] = useState(false);
  const [tempSelection, setTempSelection] = useState<CartItem>();
  const [search, setSearch] = useState("");
  const {
    items,
    updateItem,
    addItem,
    removeItem,
    emptyCart,
    calculateCost,
    getBill,
  } = useCart(account);

  const searchResults = useMemo(
    () =>
      account?.products?.filter(
        (p) =>
          p.name.toLowerCase().includes(search.toLowerCase()) ||
          (p.options &&
            p.options.some((o) =>
              o.name.toLowerCase().includes(search.toLowerCase())
            ))
      ),
    [account?.products, search]
  );

  const closeSelectionDialog = useCallback(() => {
    setTempSelection(undefined);
  }, [setTempSelection]);

  const finishOrderSelection = useCallback(
    (item: CartItem) => {
      updateItem(item);
      setTempSelection(undefined);
      openCart(true);
    },
    [updateItem, setTempSelection, openCart]
  );

  const onProductSelectedHandler = useCallback(
    async (product: Product, qty: number) => {
      const cartItem = addItem(product, qty);
      setTempSelection(cartItem);
    },
    [addItem, setTempSelection]
  );

  if (loading) {
    return (
      <Box sx={{ width: "100%", height: "100vh" }}>
        <CenterContent>
          <Typography>Loading...</Typography>
        </CenterContent>
      </Box>
    );
  }

  if (!loading && (!account || !account.products)) {
    console.log("No account");
    return <></>;
  }

  const bill = account ? getBill(account) : { orderTotal: 0 };

  return (
    <Layout>
      <Stack>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          gap={1}
        >
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="search"
            name="search"
            autoComplete="off"
            value={search}
            size="small"
            placeholder="Search"
            style={{ borderRadius: 24, marginRight: 4, marginLeft: 6 }}
            onChange={(e) => setSearch(e.currentTarget.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <Stack padding={1}>
            <IconButton
              size="small"
              onClick={items.length ? () => openCart(true) : undefined}
              style={{ padding: 1 }}
            >
              <Badge badgeContent={items.length ?? ""} color="error">
                <ShoppingBasketOutlined fontSize="medium" />
              </Badge>
            </IconButton>
            <Typography mt={0}>
              <span style={{ fontSize: 8, color: "gray" }}>GHS</span>
              {bill.orderTotal.toFixed(2)}
            </Typography>
          </Stack>
        </Box>
        {searchResults && searchResults.length > 0 && (
          <ProductsDisplay
            products={searchResults}
            addItem={onProductSelectedHandler}
          />
        )}
        {(!searchResults || searchResults.length === 0) && (
          <Stack justifyContent="center" alignItems="center" pt={8}>
            <Typography>
              No items to display.{" "}
              {search && (
                <Button
                  variant="outlined"
                  size="small"
                  style={{ textTransform: "none" }}
                  onClick={() => setSearch("")}
                >
                  Clear search
                </Button>
              )}
            </Typography>
          </Stack>
        )}

        <ShoppingCart
          cost={bill.orderTotal.toFixed(2)}
          order={items}
          removeItem={removeItem}
          updateItem={updateItem}
          closeTray={() => openCart(false)}
          itemCostCalculator={calculateCost}
          emptyTray={emptyCart}
          isOpen={isCartOpen}
          account={account}
        />

        {tempSelection && (
          <CustomizeOrder
            item={tempSelection}
            isOpen={tempSelection !== undefined}
            onClose={closeSelectionDialog}
            onChange={finishOrderSelection}
            account={account}
          />
        )}
      </Stack>
    </Layout>
  );
}
