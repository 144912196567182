import { Typography } from "@mui/material";
import { useMemo } from "react";

type CurrencyProps = {
  symbol?: string;
  amount: number;
  variant?: "small" | "medium" | "large";
};

export default function Currency({
  symbol,
  amount,
  variant = "small",
}: CurrencyProps) {
  const sizeVariant = useMemo(() => {
    if (variant === "large") return "h6";
    if (variant === "medium") return "inherit";
    return "inherit";
  }, [variant]);

  const fontSize = variant === "small" ? 9.25 : variant === "medium" ? 12 : 18;
  return (
    <Typography variant={sizeVariant} fontSize={fontSize} fontWeight={400}>
      <span style={{ fontSize: 9, color: "grey" }}>{symbol ?? "GHS"}</span>
      {Number(amount).toFixed(2)}
    </Typography>
  );
}
