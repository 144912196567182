import { Security } from "@mui/icons-material";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Alert,
} from "@mui/material";
import ProgressButton from "../../components/button";
import Spacing from "../../components/space";
import { useState } from "react";

type OtpFormProps = {
  onSubmit?: (otp: string) => void;
  processing?: boolean;
  errorMessage?: string;
};

export function OtpForm(props: OtpFormProps) {
  const [otp, setOtp] = useState("");
  const { onSubmit, processing, errorMessage } = props;
  return (
    <Box p={1}>
      <Typography fontSize={24} fontWeight={400}>
        Review & Pay
      </Typography>
      <Spacing />
      <Box p={1} bgcolor="#fff">
        <Typography>Check your phone for OTP</Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="otp"
          label={otp ? "OTP" : undefined}
          name="otp"
          autoComplete="off"
          value={otp}
          size="medium"
          inputMode="tel"
          type="tel"
          placeholder="Enter OTP here"
          onChange={(e) => setOtp(e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Security />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Spacing size={24} />
      <ProgressButton
        fullWidth
        variant="contained"
        onClick={() => onSubmit?.(otp)}
        isBusy={processing}
      >
        Confirm
      </ProgressButton>
    </Box>
  );
}
