import useCart from "../../hooks/useCart";
import { Layout } from "../../layout";
import { PayForm } from "./payForm";
import { useCallback, useMemo, useState } from "react";
import { OtpForm } from "./otpForm";
import { StatusCard } from "./statusCard";
import { CenterContent } from "../../components/centerContent";
import { useLocation, useNavigate } from "react-router-dom";
import { Validator } from "../../common/validation";
import usePayments from "../../hooks/usePayment";
import { Logger } from "../../common/logger";
import config from "../../configLoader";
import { Box, Typography } from "@mui/material";
import { Account } from "../../types";

const SendOTPStatus = "Awaiting OTP";

const FormState = {
  checkout: "checkout",
  confrimOtP: "confirm-otp",
  success: "success",
  error: "error",
};

export function CheckoutPage() {
  const { state } = useLocation();
  const navigateTo = useNavigate();
  const [formState, setFormState] = useState(FormState.checkout);
  const [errorMessage, setErrorMessage] = useState("");
  const { createPayment, confirmOTP, processing } = usePayments(
    config.apiEndpoint
  );
  const account = state?.account as Account;
  const [payResponse, setPayResponse] = useState<any>(undefined);
  const {
    items,
    getBill,
    emptyCart,
    updateDelivery,
    ready: isCartLoaded,
  } = useCart(account);

  const bill = account
    ? getBill(account)
    : { orderTotal: 0, totalCost: 0, serviceFee: 0, deliveryCost: 0 };

  const checkoutAndPayHandler = useCallback(
    async (
      fullName: string,
      phoneNumber: string,
      provider: string,
      deliver: any
    ) => {
      let errorMessage = "";
      if (!Validator.isValidPhoneNumber(phoneNumber)) {
        errorMessage = "Please enter a valid phone number";
      } else if (!provider) {
        errorMessage = "Please select network provider";
      } else if (
        account.deliveryEnabled &&
        deliver &&
        deliver.method === "deliver" &&
        !deliver.area
      ) {
        errorMessage = "Please select delivery location";
      } else if (!fullName) {
        errorMessage = "Please enter your name";
      } else if (fullName.split(" ").length < 2) {
        errorMessage = "Please enter your full name";
      }

      setErrorMessage(errorMessage);
      if (errorMessage) {
        return;
      }

      try {
        const nameParts = fullName.split(" ");
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(" ");
        const orderData = {
          items: items.map((o) => ({
            ...o.product,
            quantity: o.quantity,
            selection: o.product?.selection?.filter((s) => s.quantity),
          })),
          customer: { phoneNumber, firstName, lastName },
          delivery: deliver,
        };
        const results = await createPayment({
          description: `Order/Payment from ${phoneNumber}`,
          amount: Number(bill.totalCost).toFixed(2),
          payment: {
            accountNumber: phoneNumber,
            provider,
          },
          order: orderData,
        });

        setPayResponse(results);
        if (results.payStatus === SendOTPStatus) {
          setFormState(FormState.confrimOtP);
        } else if (results.payStatus === "Failed") {
          setFormState(FormState.error);
          setErrorMessage(
            "Oops! Something went wrong with your payment. Please try again"
          );
        } else {
          setFormState(FormState.success);
          emptyCart();
        }
      } catch (err) {
        Logger.error(`Unable to create payment. ${err}`, { config });
        setErrorMessage(
          "The payment failed. We were unable to send your order"
        );
      }
    },
    [
      setFormState,
      setErrorMessage,
      createPayment,
      emptyCart,
      bill.totalCost,
      account.deliveryEnabled,
      items,
    ]
  );

  const sendOtpHandler = useCallback(
    async (otp: string) => {
      try {
        await confirmOTP(
          otp,
          payResponse?.id,
          payResponse?.payId,
          payResponse?.apiVersion
        );
        setFormState(FormState.success);
        emptyCart();
      } catch (err) {
        Logger.error(`Unable to confirm OTP. ${err}`, { config });
        setErrorMessage(
          "The payment failed. We were unable to confirm the OTP"
        );
      }
    },
    [setFormState, setErrorMessage, confirmOTP, emptyCart, payResponse]
  );

  const pageContentToDisplay = useMemo(() => {
    if (formState === FormState.checkout) {
      return (
        <PayForm
          orderTotal={bill.orderTotal}
          serviceFee={bill.serviceFee}
          deliveryFee={bill.deliveryCost}
          totalCost={bill.totalCost}
          errorMessage={errorMessage}
          loading={processing}
          onCheckout={checkoutAndPayHandler}
          updateLocation={updateDelivery}
        />
      );
    }

    if (formState === FormState.confrimOtP) {
      return (
        <CenterContent>
          <OtpForm
            errorMessage={errorMessage}
            processing={processing}
            onSubmit={sendOtpHandler}
          />
        </CenterContent>
      );
    }

    if (formState === FormState.success) {
      return (
        <Box height="60vh">
          <CenterContent>
            <StatusCard
              severity="success"
              message="Complete payment on your phone"
              secondaryAction={{
                onClick: () => navigateTo("/"),
                text: "Order Again",
              }}
              detail={
                <Typography color="gray" pb={1} textAlign="center">
                  Please confirm the payment on your phone to complete the
                  order. You will receive a confirmation SMS when the payment is
                  complete.
                </Typography>
              }
            />
          </CenterContent>
        </Box>
      );
    }

    if (formState === FormState.error) {
      return (
        <Box height="60vh">
          <CenterContent>
            <StatusCard
              severity="error"
              message="Payment Failed"
              primaryAction={{
                text: "Try again",
                onClick: () => setFormState(FormState.checkout),
              }}
              detail={
                <Typography color="gray" pb={1} textAlign="center">
                  There was a problem sending your order. Please make sure your
                  phone number is correct and has enough balance in your wallet
                  and try again.
                </Typography>
              }
            />
          </CenterContent>
        </Box>
      );
    }
  }, [
    formState,
    errorMessage,
    processing,
    bill.serviceFee,
    bill.totalCost,
    bill.deliveryCost,
    bill.orderTotal,
    navigateTo,
    updateDelivery,
    checkoutAndPayHandler,
    sendOtpHandler,
  ]);

  // If cart is not loaded, show nothing
  if (!isCartLoaded) {
    return <></>;
  }

  // If no account, show nothing
  if (!state?.account) {
    console.log("No account");
    return <></>;
  }

  // If no items in cart, and not in success or error state, redirect to home
  if (
    items.length === 0 &&
    formState !== FormState.success &&
    formState !== FormState.error
  ) {
    navigateTo("/");
  }

  return <Layout>{pageContentToDisplay}</Layout>;
}
