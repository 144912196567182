import { Stack, Typography, styled } from "@mui/material";
import useProfile from "./hooks/useProfile";
import { Box } from "@mui/system";
import { Account } from "./types";
import { Call, LocationCity } from "@mui/icons-material";
import config from "./configLoader";
type LayoutProps = {
  children: string | JSX.Element | JSX.Element[] | undefined;
};

const BodyContainer = styled(Box)(({ theme }) => ({
  minHeight: "80vh",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "40%",
  },
}));

export function Layout({ children }: LayoutProps) {
  const { account, loading } = useProfile();
  if (loading) {
    <Stack margin={0} padding={0} alignItems="center">
      <div>Loading...</div>
    </Stack>;
  }

  if (!account) {
    <div></div>;
  }

  return (
    <Stack margin={0} padding={0} alignItems="center">
      <Header account={account} />
      <BodyContainer>{children}</BodyContainer>
      <Footer account={account} />
    </Stack>
  );
}

const HeaderContainer = styled(Stack)`
  padding: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  min-height: 10vh;
  background-color: #fff;
`;

function Header({ account }: { account?: Account }) {
  return (
    <HeaderContainer direction="row" justifyContent="space-between">
      <Box>
        {account?.logo && (
          <img
            style={{ maxHeight: 80, maxWidth: 100, margin: 0 }}
            alt="logo"
            src={
              account?.logo ??
              "https://nkapre-public-images.s3.amazonaws.com/joba2003-logo"
            }
          />
        )}
      </Box>
      <Box>
        <Typography variant="h6">{account?.name}</Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Typography fontSize={10}>
            <LocationCity fontSize="small" />
            {account?.address} {account?.city}
          </Typography>
          <Typography fontSize={10}>
            <Call fontSize="small" />
            <a href={`tel:${account?.phoneNumber}`}>{account?.phoneNumber}</a>
          </Typography>
        </Box>
      </Box>
    </HeaderContainer>
  );
}

const FooterContainer = styled(Stack)`
  height: 10vh;
  width: 100%;
`;

function Footer({ account }: { account?: Account }) {
  return (
    <FooterContainer alignItems="center" justifyContent="center">
      <Typography fontSize={9} fontWeight={600}>
        {account?.name} &copy; {new Date().getFullYear()}
      </Typography>
      <Typography fontSize={9} color="gray">
        powered by TD Technologies LTD.
      </Typography>
      <Typography fontSize={9} color="gray">
        V {config.timestamp}
      </Typography>
    </FooterContainer>
  );
}
