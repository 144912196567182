import { IconButton, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { Add, Remove } from "@mui/icons-material";

export type QuantityEditorProps = {
  value: number;
  onChange?: (newValue: any) => void;
  minimum?: number;
  maximum?: number;
};

export function QuantityEditor(props: QuantityEditorProps) {
  const { value, onChange, minimum, maximum } = props;
  const [currentValue, setValue] = useState(value);

  const increment = useCallback(
    (inc: number) => {
      if (inc < 0 && currentValue <= (minimum ?? 1)) return;
      if (maximum && inc > 0 && currentValue >= maximum) return;
      const newVal = inc + currentValue;
      setValue(newVal);
      onChange && onChange(newVal);
    },
    [currentValue, setValue, onChange, maximum, minimum]
  );

  return (
    
      <TextField
        variant="outlined"
        margin="dense"
        autoComplete="off"
        value={currentValue}
        size="small"
        style={{ width: "130px", textAlign: "center"}}
        placeholder="Qty"
        
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => increment(1)} size="small">
              <Add />
            </IconButton>
          ),
          startAdornment: (
            <IconButton onClick={() => increment(-1)} size="small">
              <Remove />
            </IconButton>
          ),
        }}
      />
  );
}
