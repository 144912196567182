import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import Currency from "../../components/currency";
import Spacing from "../../components/space";
import ProgressButton from "../../components/button";
import { Link } from "react-router-dom";
import {
  DeliveryDining,
  DirectionsWalk,
  Person2Outlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import { useCallback, useState } from "react";
import { Location } from "../../types";
import useProfile from "../../hooks/useProfile";

export type CheckoutFormProps = {
  orderTotal: number;
  serviceFee: number;
  deliveryFee: number;
  totalCost: number;
  loading?: boolean;
  errorMessage?: string;
  onCheckout?: (
    fullName: string,
    phoneNumber: string,
    provider: string,
    deliver?: any
  ) => void;
  updateLocation?: (method: string, locationId: string) => void;
  locations?: Location[];
};

const BillLine = styled(Stack)(({ theme }) => ({
  width: "100%",
  justifyContent: "space-between",
  borderRadius: 4,
  marginBottom: theme.spacing(1),
}));

export function PayForm(props: CheckoutFormProps) {
  const {
    orderTotal,
    serviceFee,
    deliveryFee,
    totalCost,
    loading,
    onCheckout,
    updateLocation,
    errorMessage,
  } = props;

  const { account } = useProfile();
  const [provider, setProvider] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("deliver");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");

  const locations = account?.locations;
  const deliveryEnabled = account?.deliveryEnabled;

  const checkoutAndPayHandler = useCallback(() => {
    if (onCheckout) {
      let deliver = null;
      if (deliveryMethod === "deliver") {
        deliver = {
          area: location,
          address,
          fee: deliveryFee,
          method: deliveryMethod,
        };
      } else if (deliveryMethod === "pickup") {
        deliver = {
          method: deliveryMethod,
        };
      }
      onCheckout(fullName, phoneNumber, provider, deliver);
    }
  }, [
    onCheckout,
    phoneNumber,
    provider,
    deliveryMethod,
    location,
    address,
    deliveryFee,
    fullName,
  ]);

  const handleLocationChange = useCallback(
    async (newLocation: string) => {
      setLocation(newLocation);
      if (updateLocation) {
        updateLocation(deliveryMethod, newLocation);
      }
    },
    [setLocation, updateLocation, deliveryMethod]
  );

  const handleDeliveryMethodChange = useCallback(
    async (method: string) => {
      setDeliveryMethod(method);
      updateLocation && updateLocation(method, location || "");
      if (method === "pickup") {
        setLocation("");
      }
    },
    [setDeliveryMethod, updateLocation, setLocation, location]
  );

  return (
    <Box p={1}>
      <Typography fontSize={24} fontWeight={400}>
        Review & Pay
      </Typography>
      <Spacing />
      <Box p={1} bgcolor="#fff">
        <BillLine direction="row" spacing={2}>
          <Typography>Order </Typography>
          <Currency amount={orderTotal} variant="large" />
        </BillLine>
        {deliveryMethod === "deliver" && (
          <BillLine direction="row" spacing={2}>
            <Typography>Delivery</Typography>
            <Currency amount={deliveryFee} variant="large" />
          </BillLine>
        )}
        <BillLine direction="row" spacing={2}>
          <Typography>Service fee</Typography>
          <Currency amount={serviceFee} variant="medium" />
        </BillLine>
        <Divider />
        <Spacing />

        <Spacing />
        <Typography>Payment method</Typography>
        <Stack direction="row" justifyContent="center">
          <ToggleButtonGroup
            color="primary"
            value={provider}
            exclusive
            onChange={(_, v) => setProvider(v)}
            aria-label="Platform"
            disabled={loading}
            fullWidth
          >
            <ToggleButton value="mtn">MTN MoMo</ToggleButton>
            <ToggleButton value="voda">Voda Cash</ToggleButton>
            <ToggleButton value="airtel">Airtel-Tigo</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phoneNumber"
          label={phoneNumber ? "Phone Number" : undefined}
          name="phoneNumber"
          autoComplete="off"
          value={phoneNumber}
          size="medium"
          inputMode="tel"
          type="tel"
          placeholder="Phone number"
          disabled={loading}
          onChange={(e) => setPhoneNumber(e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneAndroidOutlined />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="fullName"
          label={fullName ? "Your name" : undefined}
          name="fullName"
          autoComplete="off"
          value={fullName}
          size="medium"
          inputMode="text"
          type="text"
          placeholder="Enter your name"
          disabled={loading}
          onChange={(e) => setFullName(e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person2Outlined />
              </InputAdornment>
            ),
          }}
        />
        {deliveryEnabled && (
          <>
            <Typography variant="caption">
              How do you want to receive your order?
            </Typography>
            <Stack direction="row" justifyContent="center">
              <ToggleButtonGroup
                color="primary"
                value={deliveryMethod}
                exclusive
                onChange={(_, v) => handleDeliveryMethodChange(v)}
                aria-label="Platform"
                disabled={loading}
                fullWidth
              >
                <ToggleButton value="deliver">
                  Deliver <DeliveryDining />
                </ToggleButton>
                <ToggleButton value="pickup">
                  I will pickup <DirectionsWalk />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            {deliveryMethod === "deliver" && (
              <>
                <Spacing />
                <FormControl fullWidth>
                  <InputLabel id="delivery-loc">
                    {location ? "Area" : "Select delivery area"}
                  </InputLabel>
                  <Select
                    fullWidth
                    value={location ?? ""}
                    label="Area"
                    onChange={(e) =>
                      handleLocationChange(e.target.value as string)
                    }
                  >
                    {locations?.map((loc) => (
                      <MenuItem key={loc.name} value={loc?.name ?? ""}>
                        {loc.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={address ? "Delivery address" : undefined}
                  name="address"
                  autoComplete="off"
                  value={address}
                  size="medium"
                  multiline
                  rows={2}
                  placeholder="Landmark, street name or something to direct us, etc"
                  disabled={loading}
                  onChange={(e) => setAddress(e.currentTarget.value)}
                />
              </>
            )}
          </>
        )}
      </Box>
      <Spacing size={24} />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <ProgressButton
        fullWidth
        variant="contained"
        isBusy={loading}
        onClick={checkoutAndPayHandler}
      >
        Checkout & Pay <Spacing variant="vertical" />
        <Currency amount={totalCost} variant="large" />
      </ProgressButton>
      <Spacing size={40} />
      <Link to="/">
        <Button fullWidth variant="text">
          Continue Shopping
        </Button>
      </Link>
    </Box>
  );
}
