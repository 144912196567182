import { useCallback, useEffect, useMemo, useState } from "react";
import { Account, Product } from "../types";
import { Logger } from "../common/logger";
import useDelivery from "./useDelivery";

const storageKey = "order";

export type CartItem = {
  key: string;
  quantity: number;
  product: {
    id?: string;
    name: string;
    imageFile: string;
    price: number;
    selection?: { name: string; price: number; quantity: number }[];
  };
};

function calculateCost(item: CartItem) {
  const selections = item.product.selection ?? [];
  const unitCost =
    Number(item.product.price) +
    selections.reduce((sum, { quantity, price }) => {
      return sum + Number(quantity) * Number(price);
    }, 0);
  return (unitCost * item.quantity).toFixed(2);
}

export default function useCart(account?: Account) {
  const [items, setItems] = useState<CartItem[]>([]);
  const { deliveryFee, updateDelivery } = useDelivery(account);
  const [isLoaded, setIsLoaded] = useState(false);

  const addItem = useCallback(
    (product: Product, quantity: number) => {
      const newItem = {
        key: `${new Date().getTime()}.${product.id}`,
        quantity,
        product: {
          id: product.id,
          name: product.name,
          imageFile: product.imageUrl,
          price: product.price,
          selection:
            product.options?.map((o) => ({
              name: o.name,
              price: o.price,
              quantity: 0,
            })) || undefined,
        },
      };
      const newItems: CartItem[] = [...items, newItem];

      setItems(newItems);
      persist(newItems);
      return newItem;
    },
    [setItems, items]
  );

  const removeItem = useCallback(
    (key: string) => {
      const newItems = items.filter((i) => i.key !== key);
      setItems(newItems);
      persist(newItems);
    },
    [items, setItems]
  );

  const emptyCart = useCallback(() => {
    const newItems: CartItem[] = [];
    setItems(newItems);
    persist(newItems);
  }, [setItems]);

  const updateItem = useCallback(
    (item: CartItem) => {
      const oldItem = items.find((i) => i.key === item.key) || {};

      const newItems = items.filter((i) => i.key !== item.key);
      newItems.push({ ...oldItem, ...item });

      setItems(newItems);
      persist(newItems);
    },
    [items, setItems]
  );

  const costOfOrder = useMemo(() => {
    return items
      .reduce((sum, item) => {
        return sum + Number(calculateCost(item));
      }, 0)
      .toFixed(2);
  }, [items]);

  useEffect(() => {
    const savedOrderJson = localStorage.getItem(storageKey);
    if (!savedOrderJson) return;
    try {
      const order = JSON.parse(savedOrderJson);
      setItems(order);
    } catch (err) {
      Logger.error(err);
    } finally {
      setIsLoaded(true);
    }
  }, [setItems, setIsLoaded]);

  const getBill = (account: Account) => {
    const orderTotal = Number(costOfOrder);
    const deliveryCost = Number(deliveryFee);
    const subTotal = orderTotal + deliveryCost;
    const serviceFee = Number(
      (Number(account?.serviceFeeRate ?? 0.03) * Number(subTotal)).toFixed(2)
    );
    const totalCost = Number(
      (orderTotal + serviceFee + deliveryCost).toFixed(2)
    );
    return { serviceFee, orderTotal, totalCost, deliveryCost };
  };

  return {
    items,
    getBill,
    addItem,
    updateItem,
    removeItem,
    emptyCart,
    calculateCost,
    updateDelivery,
    ready: isLoaded,
  };
}

function persist(order: CartItem[]) {
  localStorage.setItem(storageKey, JSON.stringify(order));
}
