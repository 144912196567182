import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { CartItem } from "../hooks/useCart";
import { QuantityEditor } from "./QuantityEditor";
import { useCallback, useMemo } from "react";
import Spacing from "./space";
import Currency from "./currency";
import { useNavigate } from "react-router-dom";
import { Account } from "../types";

export type ShoppingCartProps = {
  account?: Account;
  order: CartItem[];
  cost: string;
  isOpen?: boolean;
  updateItem: (item: CartItem) => void;
  removeItem: (key: string) => void;
  emptyTray?: () => void;
  openTray?: () => void;
  closeTray?: () => void;
  editCustomization?: (item: CartItem) => void;
  itemCostCalculator?: (item: CartItem) => string;
};

export function ShoppingCart(props: ShoppingCartProps) {
  const navigateTo = useNavigate();

  const {
    order,
    cost,
    account,
    closeTray,
    removeItem,
    updateItem,
    isOpen,
    editCustomization,
    itemCostCalculator,
  } = props;

  const onQuantityChanged = useCallback(
    (p: CartItem, quantity: number) => {
      const newItem = { ...p, quantity };
      updateItem(newItem);
    },
    [updateItem]
  );

  const sortedOrder = useMemo(
    () =>
      order.sort((a, b) => {
        if (a.key < b.key) return -1;
        if (a.key > b.key) return 1;
        return 0;
      }),
    [order]
  );

  const editOrderItemHandler = useCallback(
    (e: any, item: CartItem) => {
      e.preventDefault();
      editCustomization && editCustomization(item);
      closeTray && closeTray();
    },
    [editCustomization, closeTray]
  );

  return (
    <Drawer anchor="right" open={isOpen} onClose={closeTray}>
      <Stack p={1} style={{ backgroundColor: "#ededed", height: "100%" }}>
        {sortedOrder?.length > 0 && (
          <div>
            {sortedOrder.map((p) => {
              return (
                <CartLineItem
                  key={p.key}
                  item={p}
                  editOrderItemHandler={editOrderItemHandler}
                  onQuantityChanged={onQuantityChanged}
                  itemCostCalculator={itemCostCalculator}
                  removeItem={removeItem}
                />
              );
            })}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              p={1}
            >
              <Typography>Total</Typography>{" "}
              <Currency amount={Number(cost)} variant="large" />
            </Stack>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigateTo("/checkout", { state: { account } })}
            >
              Checkout & Pay
            </Button>

            <Spacing size={68} />
            <Button variant="text" fullWidth onClick={closeTray}>
              Continue Shopping
            </Button>
          </div>
        )}
        {!sortedOrder?.length && (
          <Box
            p={2}
            pt={8}
            width="100%"
            height="100%"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize={14} fontWeight={400} color="grey">
              Your cart is empty
            </Typography>
          </Box>
        )}
      </Stack>
    </Drawer>
  );
}

type CartLineItemProps = {
  item: CartItem;
  itemCostCalculator?: (item: CartItem) => string;
  editOrderItemHandler?: (e: any, item: CartItem) => void;
  onQuantityChanged?: (item: CartItem, quantity: number) => void;
  removeItem: (key: string) => void;
};

function CartLineItem(props: CartLineItemProps) {
  const {
    item,
    editOrderItemHandler,
    itemCostCalculator,
    onQuantityChanged,
    removeItem,
  } = props;
  const selectedOptions = item.product?.selection?.filter((x) => x.quantity);
  const itemCost = useMemo(
    () => (itemCostCalculator && itemCostCalculator(item)) ?? "0",
    [itemCostCalculator, item]
  );

  return (
    <Stack
      p={1}
      mb={2}
      key={item.key}
      style={{
        backgroundColor: "#fff",
        borderRadius: 4,
        width: "100%",
        minWidth: 300,
      }}
      alignItems="start"
      justifyContent="center"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography fontSize={14} fontWeight={400}>
          {item.product.name}
        </Typography>
        <IconButton
          size="small"
          onClick={() => item.key && removeItem(item.key)}
        >
          <Cancel fontSize="small" />
        </IconButton>
      </Stack>
      {selectedOptions?.length ? (
        <Typography color="gray" fontSize={11}>
          ({selectedOptions.map((o) => o.name).join(", ")})
          <a
            href="https://"
            onClick={(e) =>
              editOrderItemHandler && editOrderItemHandler(e, item)
            }
            style={{ marginLeft: 8 }}
          >
            Change
          </a>
        </Typography>
      ) : null}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="90%"
      >
        <QuantityEditor
          value={item.quantity}
          minimum={1}
          maximum={25}
          onChange={(val) => onQuantityChanged && onQuantityChanged(item, val)}
        />
        <Currency amount={Number(itemCost)} variant="medium" />
      </Stack>
    </Stack>
  );
}
