import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useCallback } from "react";
import { Add } from "@mui/icons-material";
import { Product } from "../types";

export type ProductDisplayProps = {
  product: Product;
  addItem?: (product: Product, quantity: number) => void;
};

const ProductCard = styled(Card)(({ theme }) => ({
  borderColor: "#f4f6fd",
  border: "1px",
  padding: 8,
  margin: 8,
  width: 165,
  marginBottom: 12,
  [theme.breakpoints.up("sm")]: {
    width: 260,
    marginBottom: 24,
  },
}));

const ProductCardHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: 12.5,
  fontWeight: 600,
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    fontSize: 16,
  },
}));

const ProductCardCurrency = styled(Typography)(({ theme }) => ({
  fontSize: 9,
  fontWeight: 400,
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    fontSize: 12,
  },
}));

const ProductCardAmount = styled(Typography)(({ theme }) => ({
  fontSize: 12.5,
  fontWeight: 400,
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    fontSize: 14,
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    fontSize: 14,
  },
}));

export function ProductDisplay(props: ProductDisplayProps) {
  const { product, addItem } = props;

  const addToOrderTray = useCallback(
    () => addItem && addItem(product, 1),
    [product, addItem]
  );

  const options = product?.options || [];
  const minPrice = product.price || Math.min(...options.map((o) => o.price));
  const maxPrice = product.price || Math.max(...options.map((o) => o.price));

  return (
    <ProductCard>
      <ProductCardHeaderText>{product.name}</ProductCardHeaderText>
      {product.imageUrl && (
        <CardMedia component="img" image={product.imageUrl} />
      )}
      <CardContent>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <ProductCardCurrency color="grey">GHS</ProductCardCurrency>
          <ProductCardAmount variant="h5">{minPrice}</ProductCardAmount>
          {maxPrice > minPrice && (
            <>
              &nbsp;-&nbsp;
              <ProductCardCurrency color="grey">GHS</ProductCardCurrency>
              <ProductCardAmount variant="h5">{maxPrice}</ProductCardAmount>
            </>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <AddButton
          variant="contained"
          fullWidth
          size="small"
          onClick={addToOrderTray}
        >
          Add <Add />
        </AddButton>
      </CardActions>
    </ProductCard>
  );
}

type ProductsDisplayProps = {
  products: Product[];
  addItem?: (product: Product, quantity: number) => void;
};

export function ProductsDisplay(props: ProductsDisplayProps) {
  const { products, addItem } = props;

  return (
    <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
      {products.map((p) => (
        <ProductDisplay product={p} addItem={addItem} key={p.id} />
      ))}
    </Stack>
  );
}
